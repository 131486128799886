.NotFound{
    padding-bottom: 100px;
    padding-top: 100px;
    width: 100%;
    background-image: url(../../../public/img/bg404.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #131313 26.04%, rgba(19, 19, 19, 0.21) 100%);
    }
    .NotFoundWrapper{
        position: relative;
        max-width: 1200px;
        width: 100%;
        margin: 24px auto;
        display: flex;
        justify-content: space-between;
        z-index: 10;
        .left{
            text-align: left;
            max-width: 630px;
            p{
                font-size: 32px;
                line-height: 120%;
                letter-spacing: -0.64px;
                color: #F9FFFE;
                margin-bottom: 16px;
            }
            span{
                color: #C3C3C3;
                line-height: 24px;
                display: block;
                margin-bottom: 32px;
            }
            .homeBtn{
                background-color: #004E41;
                color: #fff;
                padding: 16px;
                font-size: 20px;
                letter-spacing: -.4px;
                border-radius: 4px;
                width: 280px;
                display: block;
                text-align: center;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
        .right{
            flex: 1;
            display: flex;
            position: relative;
            justify-content: flex-end;
            img{
                max-width: 423px;
            }
            .class_404{
                font-family: 'BlackProDisplay';
                position: absolute;
                bottom: -130px;
                right: 300px;
                font-size: 220px;
                margin: 0;
            }
        }
    }
}

@media(max-width: 992px){
    .NotFound{
        background-position: left 40px;
        padding-top: 32px;
        padding-bottom: 32px;
        background-size: contain;
        &::after{
            display: none;
        }
        .NotFoundWrapper{
            flex-direction: column;
            flex-wrap: wrap;
            .left{
                order: 2!important;
                margin-top: 32px;
                p{
                    font-size: 24px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                span{
                    padding-left: 20px;
                }
                .homeBtn{
                    width: 95%;
                    margin: 0 auto;
                }
                .secondTitle{
                    left: 0;;
                    text-align: right;
                    img{
                        position: absolute;
                        right: -20px;
                        top: -20px;
                        width: 140px;
                    }
                }
            }
            .right{
                order: 1!important;
                img{
                    width: 50%;
                }
                .class_404{
                    top: 100%;
                    left: 30%;
                    font-size: 78px;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    
}